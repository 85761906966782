#root {
  button {
    background-color: #ffffff3f !important;
    color: var(--text-color) !important;
    border: 1px $white solid !important;
    text-transform: none !important;
    font-family: $default-font !important;
    font-weight: bold !important;
    border-radius: 100px !important;
    padding: 12px 24px !important;
    min-width: 180px !important;
    box-shadow: 0px 0px 5px 0px #38383829;
  }

  button {
    &.regular-button {
      position: relative;
      display: inline-flex !important;
      padding-left: 16px !important;
      padding-right: 16px !important;
      justify-content: center;
      margin: 8px;
      width: fit-content;
      min-height: 40px !important;
      max-height: 40px;
      border-radius: 8px !important;
      background-color: $black-1 !important;
      color: $white !important;
      padding: 0px 16px;
      border: none;
      font-size: 0.8rem !important;
      text-transform: none !important;
      font-family: $default-font !important;
      font-weight: bold !important;
      cursor: pointer;
      &.btn-white {
        background-color: $white !important;
        color: $background-color !important;
      }
    }
    &.icon-button {
      min-width: 0px !important;
      max-width: 10px !important;
      max-height: 42px;
      min-height: 42px;
      padding: 8px 20px !important;
    }
    &.Mui-disabled {
      opacity: 0.2;
    }
  }

  .footer {
    position: absolute;
    margin: auto;
    width: 100%;
    bottom: 2rem;
  }

  // Inputs
  label {
    text-align: left;
    p {
      margin-bottom: 8px;
    }
  }
  input {
    border: 1px solid #ffffff77;
    background-color: #00000026;
    border-radius: 12px;
    padding: 16px;
    color: $white;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
    &.has-prefix-icon {
      padding-left: 50px !important;
    }
    &.error-outline {
      outline: $error solid 1px !important;
    }
  }

  input:focus,
  textarea:focus {
    outline: $white solid 1px;
  }

  input:disabled {
    color: $text-faded;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  input[type="checkbox"] {
    width: 20px !important;
    height: 20px !important;
  }

  .loading-container {
    animation: rotate360 1s ease-in-out infinite;
    margin: 16px;
    position: relative;
    height: 150px;
    width: 150px;
    &.loading-small {
      height: 40px !important;
      width: 40px !important;
    }
    .circle {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background-color: $white;
      position: absolute;
      left: calc(50% - 25px);
      &.circle-small {
        height: 15px !important;
        width: 15px !important;
        left: calc(50% - 7.5px) !important;
      }
    }
    .circle-1 {
      top: 0;
    }
    .circle-2 {
      bottom: 0;
    }
  }

  .cookie-popup {
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100% - 64px);
    background-color: $black-1;
    padding: 16px;
    z-index: 100;
    margin: 16px;
    border-radius: 12px;
    .cookie-popup-content {
      color: $white !important;
      align-items: center;
      .cookie-popup-text {
        color: $white !important;
        font-size: 0.8rem;
        margin-right: 16px;
      }
      .cookie-popup-buttons {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        button {
          margin-left: 16px;
          color: $white !important;
        }
      }
    }
  }
  .snackbar-color-success,
  .snackbar-color-error {
    button {
      background-color: transparent !important;
      color: var(--text-color) !important;
      border: none !important;
      text-transform: none !important;
      font-family: $default-font !important;
      font-weight: bold !important;
      border-radius: 4px !important;
      padding: 8px !important;
      min-width: 10px !important;
      box-shadow: none !important;
    }
  }

  .snackbar-color-success {
    .MuiPaper-root {
      background-color: $success !important;
    }
  }
  .snackbar-color-error {
    .MuiPaper-root {
      background-color: $error !important;
    }
  }
}

.stepper-container {
  display: flex;
  max-width: 600px;
  scroll-margin-top: 100px;
  padding-left: 8px;
  padding-right: 8px;
  justify-content: space-between;
  .step-container {
    display: flex;
    animation: fadeIn 0.5s calc(var(--animation-number) * 100ms) ease-in-out
      forwards;
    .line {
      position: relative;
      top: 50%;
      height: 1px;
      background: $text-faded;
      --width: 100%;
      animation: progress 0.5s calc(var(--animation-number) * 100ms) ease-in-out
        forwards;
      &.active::before {
        width: 0%;
      }
      &.active {
        height: 2px;
        background: $blue-gradient;
        animation: progress 0.5s ease-in-out forwards;
      }
      &.has-been {
        height: 1px;
        opacity: 1;
        background: $blue-gradient;
      }
    }
    .step {
      height: 36px;
      width: 36px;
      border-radius: 50%;
      border: 1px solid $text-faded;
      text-align: center;
      color: $text-faded;
      box-sizing: border-box;
      opacity: 0;
      animation: fadeIn 0.5s calc(var(--animation-number) * 100ms) ease-in-out
        forwards;
      .step-inner {
        height: 34px;
        background: $background-color;
        width: 34px;
        border-radius: 50%;
        padding: 6px;
        box-sizing: border-box;
        transition: color 0.5s ease-in-out;
      }
      &.active {
        background: $blue-gradient-vertical;
        color: white;
        border: none;
        .step-inner {
          @include quick-fade();
          background: $blue-gradient-vertical;
          padding: 7px;
          height: 36px;
          width: 36px;
        }
      }
      &.has-been {
        background: $blue-gradient-vertical;
        padding: 1px;
        border: none !important;
        .step-inner {
          color: $info;
        }
      }
    }
  }
}
