// Margin Bottom
$sizes: "0" 0, "1" 1px, "2" 2px, "-2" -2px, "2rem" 2rem, "4" 4px, "-4" -4px,
  "4rem" 4rem, "6" 6px, "-6" -6px, "8" 8px, "-8" -8px, "10" 10px, "-10" -10px,
  "16" 16px, "20" 20px, "32" 32px, "40" 40px, "48" 48px, "-48" -48px, "62" 62px,
  "100" 100px, "120" 120px, "300" 300px, "-auto" auto;

@each $name, $size in $sizes {
  .m#{$name} {
    margin: $size !important;
  }

  .mt#{$name} {
    margin-top: $size !important;
  }

  .mb#{$name} {
    margin-bottom: $size !important;
  }

  .ml#{$name} {
    margin-left: $size !important;
  }

  .mr#{$name} {
    margin-right: $size !important;
  }

  .pt#{$name} {
    padding-top: $size !important;
  }

  .pb#{$name} {
    padding-bottom: $size !important;
  }

  .pl#{$name} {
    padding-left: $size !important;
  }

  .pr#{$name} {
    padding-right: $size !important;
  }

  .p#{$name} {
    padding: $size !important;
  }
}

.t0 {
  top: 0;
}
.t4 {
  top: 4px !important;
}
.r0 {
  right: 0;
}

// Width
.w100p-lg-down {
  @media only screen and (max-width: 900px) {
    width: 100% !important;
  }
}

.w50p-lg-up {
  @media only screen and (min-width: 900px) {
    width: 50% !important;
  }
}

.flex-w60p {
  width: calc(60% - 10px);
}

.flex-w50p {
  width: calc(50% - 10px);
}

.flex-w40p {
  width: calc(40% - 10px);
}

.flex-w30p {
  width: calc(30% - 10px);
}

.w100p {
  width: 100% !important;
}

.w80p {
  width: 80% !important;
}

.w60p {
  width: 60% !important;
}

.w50p {
  width: 50% !important;
}

.w40p {
  width: 40% !important;
}

.w30p {
  width: 30% !important;
}

.min-w70 {
  min-width: 70px;
}
.min-w180 {
  min-width: 180px;
}

.min-w200 {
  min-width: 200px !important;
}

.min-w300 {
  min-width: 300px !important;
}

.min-w400 {
  min-width: 400px !important;
}

.max-w650 {
  max-width: 650px;
}

.max-w600 {
  max-width: 600px;
}

.max-w500 {
  max-width: 500px;
}

.max-w400 {
  max-width: 400px;
}

.max-w200 {
  max-width: 200px;
}

.max-w90p {
  max-width: 90%;
}

// Height
.max-h400 {
  max-height: 400px;
}

.min-h310 {
  min-height: 310px;
}

.min-h150 {
  min-height: 150px;
}

.min-h138 {
  min-height: 138px;
}

.min-h115 {
  min-height: 115px;
}

.min-h48 {
  min-height: 48px;
}

.h100p {
  height: 100%;
}

// Positions
.pos-abs {
  position: absolute;
}

.pos-rel {
  position: relative;
}

// Displays
.d-flex {
  display: flex;
}

.d-grid {
  display: grid;
}

.flex-wrap {
  flex-wrap: wrap;
}

.gap8 {
  gap: 8px;
}

.gap16 {
  gap: 16px;
}

.gap20 {
  gap: 20px;
}

.flex-grow {
  flex-grow: 1;
}

.flex-item-wrap {
  flex-flow: row wrap;
}

// Other
.cursor-pointer {
  cursor: pointer;
}

.jc-center {
  justify-content: center;
}

.jc-start {
  justify-content: start;
}

.jc-space-around {
  justify-content: space-around;
}

.jc-space-between {
  justify-content: space-between;
}

.flex-d-col {
  flex-direction: column;
}

.overflow-scroll {
  overflow: scroll;
}
