@mixin slow-fade {
  animation: fadeIn 2s ease-in-out;
}

@mixin quick-fade {
  animation: fadeIn 0.5s ease-in-out forwards;
}

@mixin card-background {
  @include quick-fade();
  opacity: 0;
  background-color: $card-background-color;
  border-radius: 32px;
  padding: 20px;
  box-shadow: 0px 0px 5px 0px #38383829;
}

@mixin card-background-blue {
  @include card-background();
  background-image: $blue-gradient-faded !important;
}

@mixin text-blue-gradient {
  color: $blue-gradient;
  background: $blue-gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin text-instagram-gradient {
  color: $instagram-gradient;
  background: $instagram-gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
