body {
  position: absolute;
  text-align: center;
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #0e0e0e;
  object-fit: cover;
  background-position: 100% calc(100% + 4rem);
  background-image: url("https://uploads-ssl.webflow.com/63e7f7e770738021a2190b76/63e8b0882d6553290e7441aa_Waves.svg");
}

.theme-light {
  --card-background-color: #fafafa72;
  --text-color: #383838;
}
.theme-dark {
  --card-background-color: #26262666;
  --text-color: #fafafa;
}

.theme-dark,
.theme-light {
  background: var(--background-image) !important;
  background-size: cover !important;
  transition: all 1s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
p {
  transition: all 1s ease-in-out;
  margin: 0;
  color: var(--text-color);
}

.main-page {
  text-align: center;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
}

.waves-background {
  position: absolute;
  text-align: center;
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #0e0e0e;
  object-fit: cover;
  background-position: 100% calc(100% + 4rem);
  background-image: url("https://uploads-ssl.webflow.com/63e7f7e770738021a2190b76/63e8b0882d6553290e7441aa_Waves.svg");
}

.title-card {
  @include card-background();
  transition: all 1s ease-in-out;
  background-color: var(--card-background-color);
  backdrop-filter: blur(36px);
  margin-bottom: 30px;
}

.artist-carousel {
  height: 300px;
  background-size: cover;
  border-radius: 12px;
  position: relative;
}

.artist-carousel-details {
  background-color: #1c1c1ca5;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0px 0px 12px 12px;
}

.carousel-container {
  width: 900px;
  margin: auto;
  padding-bottom: 10px;
}

.carousel-card {
  @include card-background();
  transition: all 1s ease-in-out;
  margin-top: 10px;
  background-color: var(--card-background-color);
  backdrop-filter: blur(36px);
  min-height: 500px;
  max-width: 800px;
  margin: auto;
}

.tour-date-card {
  background-color: var(--card-background-color);
  border-radius: 12px;
}

.swiper {
  padding-bottom: 30px !important;
  margin-bottom: 20px;
}

.swiper-slide {
  @include quick-fade();
}

.swiper-button-prev,
.swiper-button-next {
  color: var(--text-color) !important;
  @media screen and (max-width: 800px) {
    display: none !important;
  }
}

.swiper-button-prev {
  left: 4rem !important;
  @media screen and (max-width: 800px) {
    left: 2rem !important;
  }
  button {
    min-width: 0px px !important;
    max-width: 10px !important;
  }
}

.swiper-button-next {
  right: 4rem !important;
  @media screen and (max-width: 800px) {
    right: 2rem !important;
  }
  button {
    min-width: 0px px !important;
    max-width: 10px !important;
  }
}

.swiper-button-next::after,
.swiper-button-prev::after {
  content: "" !important;
}

.swiper-pagination-bullet-active {
  background: var(--text-color) !important;
}

.socials-card {
  @include card-background();
  transition: all 1s ease-in-out;
  background-color: transparent;
  box-shadow: 0px 0px 0px 0px #ffffff00;
  @media screen and (min-width: 800px) {
    background-color: var(--card-background-color);
    backdrop-filter: blur(36px);
    box-shadow: 0px 0px 5px 0px #38383829 !important;
  }
  padding: 8px;
  width: fit-content;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .socials-container {
    height: 24px;
    width: 24px;
    margin: 8px;
    img {
      height: 24px;
      width: 24px;
      &.invert {
        filter: invert(1);
      }
    }
  }
}

.profile-pic {
  width: 260px;
  height: 260px;
  border-radius: 50%;
  object-fit: cover;
  @media screen and (max-width: 800px) {
    width: 200px;
    height: 200px;
  }
}

.youtube-video-vertical-center {
  margin-top: 10%;
}

.centered-loading-main {
  width: 100%;
  justify-content: center;
  display: flex;
  position: relative;
  top: calc(50vh - 182px);
}
