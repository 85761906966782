// Background Colors
$background-color: #000000;
$card-background-color: #c08b8b;
$card-background-color-1: #1c1c1e;
$card-background-color-faded: #13131381;
$card-background-color-super-faded: #13131332;

// Basic Colors
$black: #000000;
$black1: #0e0e0e;
$black-1: #1c1c1e;
$black-2: #262626;
$white: white;
$grey: #99989f;
$grey-1: #383838;
$grey-1-faded: #383838b8;

// Text Colors
$text-faded: #99989f;
$text-faded-1: rgb(145, 145, 145);
$text-white: rgb(255, 255, 255);
$text-blue: #05befe;

// Gradients
$blue-gradient: linear-gradient(255.56deg, #06befe 5.59%, #406bff 96.78%);
$blue-gradient-faded: linear-gradient(
  255.56deg,
  rgba(6, 190, 254, 0.1) 5.59%,
  rgba(64, 107, 255, 0.1) 96.78%
);
$blue-gradient-faded-player: linear-gradient(
  230deg,
  rgba(6, 190, 254, 0.1) 5.59%,
  #06c0fe79 20.59%,
  #406dff50 76.78%,
  rgba(64, 107, 255, 0.1) 96.78%
);
$blue-gradient-vertical: linear-gradient(180deg, #06befe 5.59%, #406bff 96.78%);

$yellow-gradient: linear-gradient(90deg, #ffae18 0%, #fdd07a 100%);
$pink-gradient: linear-gradient(90deg, #f8005e 0%, #f8629b 100%);

// Other Brand Colors
$spotify-green: #1ed760;
$facebook-blue: #1877f2;
$youtube-red: #fe3d2f;
$instagram-gradient: linear-gradient(
  90deg,
  #fb9021 5%,
  #e14567 10%,
  #9239a7 55%
);
$instagram-pink: #f54e86;
$tiktok-red: #fe2c55;
$twitter-white: $white;
$deezer-green: #29ab70;

// Notifications
$success: #009b51;
$warn: #ffae11;
$error: #f31155;
$info: #05befe;

// Statuses
$verified: #05befe29;
$pending: #ffae1133;
$rejected: #f311551a;

$pink: #f8005e;
$pink-light: #f8629b;
$pink-faded: #f8005f29;
$pink-light-faded: #f8629c25;
